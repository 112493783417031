import React, { FC } from 'react';

import Content from '../../components/base/Content';
import Items from '../../components/generic/Items';
import IconAdd from '../../components/icons/Add';
import OrganizationsQuery from '../../objects/organizations/queries';
import query from '../../utils/query';
import requiredAuth from '../../utils/requireAuth';

const Organizations = query(Items, OrganizationsQuery);

const OrganizationsPage: FC = () => (
  <Content>
    <Organizations
      addProps={{
        className: 'mt-6',
        iconLeft: IconAdd,
        size: 's',
        text: 'admin.organizations.creation.label',
        to: `/admin/organizations/create/`,
      }}
      fields={[
        {
          className: 'flex-1',
          label: 'Nom',
          name: 'name',
        },
        {
          className: 'flex-1',
          label: 'user.list.field.createdAt',
          name: 'createdAt',
          type: 'date',
          typeFormat: 'DD/MM/YY',
        },
      ]}
      itemActions={[]}
      pathname="/admin/organizations/"
    />
  </Content>
);

export default requiredAuth(OrganizationsPage, { admin: true, layout: true });
