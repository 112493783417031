import gql from 'graphql-tag';

const organizations = gql`
  query organizations(
    $filter: JSONObject
    $hitsPerPage: Int
    $order: [[String]]
    $page: Int
  ) {
    organizations(
      filter: $filter
      hitsPerPage: $hitsPerPage
      order: $order
      page: $page
    ) {
      nbHits
      nbPages
      hitsPerPage
      page
      hits {
        id
        createdAt
        name
      }
    }
  }
`;

const organizationsRetrieve = gql`
  query organizationsRetrieve($id: String!) {
    organizationsRetrieve(id: $id) {
      id
      name
      updatedAt
    }
  }
`;

export default organizations;

export { organizationsRetrieve };
